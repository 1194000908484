import Vue from 'vue';
import { Mixin } from 'vue-mixin-decorator';
import UserService from '@/services/UserService';
import pggames from '@/pggame';

@Mixin
export default class LuanchGameMixin extends Vue {
  public banks: Array<any> = [];

  public userService = new UserService();

  // eslint-disable-next-line consistent-return
  // public async luanchGame(gameId?: string, tableType?: string): Promise<string | undefined> {
  //   try {
  //     const { data } = await this.userService.luanchGame();
  //     if (gameId === '99') {
  //       return `https://www.stardna.com/v1/service-unavailable?token=${data.result.token}`;
  //     }
  //     if (gameId === '26') {
  //       return `https://www.stardna.com/v1/pgslot?token=${data.result.token}`;
  //     }
  //     return `https://www.stardna.com/v1/games?table_type=${tableType}&game_id=${gameId || ''}&token=${data.result.token}`;
  //   } catch (e) {
  //     console.log(e);
  //     this.$store.commit('Snackbar/showNotification',
  //     { message: 'เกิดข้อผิดพลาดระหว่างเปิดเกม', error: true });
  //   }
  // }

  // public async launchSpecificGame(game, tableType: (string | null) = 'casino') {
  //   let url;
  //   if (game === 'amb' || game === 'pgslot' || game === 'fishing' || game === 'lotto') {
  //     url = await this.luanchGame('99');
  //   }
  //   if (tableType === 'pgslot') {
  //     url = await this.luanchGame('26');
  //   }
  //   if (tableType === 'yeekee') {
  //     url = await this.luanchGame(this.getIdByGame(tableType), 'casino');
  //   }
  //   if (tableType === 'bikini') {
  //     url = await this.luanchGame(this.getIdByGame(game), 'bikini');
  //   }
  //   if (tableType === 'casino') {
  //     url = await this.luanchGame(this.getIdByGame(game), 'casino');
  //   }
  //   window.location.href = url;
  // }

  // eslint-disable-next-line class-methods-use-this
  public getIdByGame(key) {
    const games = {
      baccarat: '1',
      'dragon tiger': '6',
      roulette: '10',
      sicbo: '7',
      hilo: '13',
      pgslot: '26',
      yeekee: '23',
      fishing: '50',
    };
    return games[key] || '';
  }

  // // eslint-disable-next-line consistent-return
  public async launchGameLobby(gameId?: string, tableType?: string) {
    if (gameId === '26') {
      setTimeout(() => {
        window.open(`https://www.stardna.com/v1/pgslot?token=${this.$store.state.Auth.gameToken}`, '_blank');
      });
      return;
    }
    if (gameId === '50') {
      setTimeout(() => {
        window.open(`https://www.stardna.com/v1/live/aviafish/50?token=${this.$store.state.Auth.gameToken}&table_type=casino`, '_blank');
      });
      return;
    }
    setTimeout(() => {
      window.open(`https://www.stardna.com/v1/games?table_type=${tableType}&game_id=${gameId || ''}&token=${this.$store.state.Auth.gameToken}`, '_blank');
    });
  }

  public async findGameIdAndLaunchGameLobby(game, tableType?: string) {
    const gameId = this.getIdByGame(game);
    await this.launchGameLobby(gameId, tableType);
  }

  public async launchBetworld() {
    setTimeout(() => {
      window.open(`https://eagle.betworld.international/lobby?sync_token=${this.$store.state.Auth.betworldToken}`, '_blank');
    });
  }

  public generateBetworldUrl() {
    return `https://eagle.betworld.international/lobby?sync_token=${this.$store.state.Auth.betworldToken}`;
  }

  public generateRoyal() {
    return this.$store.state.Auth.royalLobbyUrl;
  }

  public async launchRoyal() {
    window.open(this.$store.state.Auth.royalLobbyUrl);
  }

  // eslint-disable-next-line class-methods-use-this
  public async launchPgSlot(provider, gameId) {
    try {
      await this.userService.updateRecentGame({
        type: 'pgslot',
        imageUrl: `https://staging.ambsupergames.com/cardGame/pgsoft/${gameId}.png`,
        provider,
        gameId,
      });
      setTimeout(() => {
        window.open(`https://www.stardna.com/v1/pgslot?token=${this.$store.state.Auth.gameToken}&provider=${provider}&gameId=${gameId}`, '_blank');
      });
      return;
    } catch (e) {
      this.$store.commit('Snackbar/showNotification', { message: 'เกิดข้อผิดพลาดระหว่างเปิดเกม', error: true });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public async launchFishGame() {
    try {
      await this.userService.updateRecentGame({
        type: 'fishing',
        imageUrl: `${process.env.VUE_APP_ASSET_ENDPOINT}/fishing.png`,
        gameId: '50',
      });
      window.open(`https://www.stardna.com/v1/live/aviafish/50?token=${this.$store.state.Auth.gameToken}&table_type=casino`, '_blank');
      return;
    } catch (e) {
      this.$store.commit('Snackbar/showNotification', { message: 'เกิดข้อผิดพลาดระหว่างเปิดเกม', error: true });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public randomPgGames() {
    const games: any = [];
    for (let i = 0; i <= 15; i += 1) {
      const game = pggames[Math.floor(Math.random() * 102) + 1];
      games.push(game);
    }
    return games;
  }

  // eslint-disable-next-line class-methods-use-this
  public getPgGames() {
    const games = pggames.slice(0, 81);
    return games;
  }

  public generateGameLobbyUrl(gameId?: string, tableType?: string): string | undefined {
    if (tableType === 'betworld') {
      return `https://eagle.betworld.international/lobby?sync_token=${this.$store.state.Auth.betworldToken}`;
    }
    if (tableType === 'royal') {
      return this.$store.state.Auth.royalLobbyUrl;
    }
    if (gameId === '26') {
      return `https://www.stardna.com/v1/pgslot?token=${this.$store.state.Auth.gameToken}`;
    }
    return `https://www.stardna.com/v1/games?table_type=${tableType}&game_id=${gameId || ''}&token=${this.$store.state.Auth.gameToken}`;
  }

  public generatePgSlotUrl(provider, gameId) {
    try {
      return `https://www.stardna.com/v1/pgslot?token=${this.$store.state.Auth.gameToken}&provider=${provider}&gameId=${gameId}`;
    } catch (e) {
      this.$store.commit('Snackbar/showNotification', { message: 'เกิดข้อผิดพลาดระหว่างเปิดเกม', error: true });
      return '#';
    }
  }

  public generateFishGameUrl() {
    try {
      return `https://www.stardna.com/v1/live/aviafish/50?token=${this.$store.state.Auth.gameToken}&table_type=casino`;
    } catch (e) {
      this.$store.commit('Snackbar/showNotification', { message: 'เกิดข้อผิดพลาดระหว่างเปิดเกม', error: true });
      return '#';
    }
  }

  public async updateRecentGame(data: {
    type,
    imageUrl,
    gameId?,
    provider?
  }) {
    try {
      await this.userService.updateRecentGame(data);
    } catch (e) {
      console.log(e);
    }
  }
}
